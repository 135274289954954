//
//
//
//
//
//
//
//
//
//
//
//
//
import goodList from '@/components/goodList/index.vue';
export default {
  name: 'GoodListFrom',
  components: {
    goodList: goodList
  },
  data: function data() {
    return {
      handleNum: '',
      visible: false,
      callback: function callback() {},
      checked: []
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getStoreItem: function getStoreItem(img) {
      this.callback(img);
      this.visible = false;
    }
  }
};